import React, { useEffect, useState } from "react"
import LangSelect from "../LangSelect"

const GartnerNav = () => {
  const [mobileDropdown, setmobileDropdown] = useState(false)

  const showmobileDropdown = () => {
    setmobileDropdown(!mobileDropdown)
  }
  // const headerRef = useRef();

  // useEffect(() => {
  //   console.log(headerRef)

  //     headerRef.current.classList.add("relative py-7 text-base text-purple-100 flex justify-center items-center m-0 cursor-pointer mx-4 hover:text-gray-darklight z-50 h-full")
  // }, [])

  return (
    <div className="w-full bg-indigo-100 shadow-darkgray flex place-content-center z-50">
      <div className="w-11/12 flex justify-between px-2 items-center">
        <div className="w-2/3 lg:w-1/3 py-3 lg:py-3">
          <a href="/">
            <img
              className="w-auto vc-logo"
              src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/04/New-VC-Logo-invert.png"
              alt="Vantage Circle Logo"
              width="273"
              height="40"
            />
          </a>
        </div>
        <div className="hidden lg:flex justify-end items-center w-1/3 lg:w-11/12">
          <div className="w-7/12 xl:w-1/2 px-5 flex justify-around items-center border-r">
            <div>
              <a
                href="#benefits"
                className="text-base text-purple-100 py-7 flex justify-center m-0 cursor-pointer mx-4 pr-9 hover:text-gray-darklight"
              >
                Benefits
              </a>
            </div>
            <div>
              <a
                href="#clienttestimony"
                className="text-base text-purple-100 py-7 flex justify-center m-0 cursor-pointer mx-4 pr-9 hover:text-gray-darklight"
              >
                Clients
              </a>
            </div>
            <div>
              <a
                href="#integrate"
                className="text-base text-purple-100 py-7 flex justify-center m-0 cursor-pointer mx-4 pr-9 hover:text-gray-darklight"
              >
                Integrations
              </a>
            </div>
            <div>
              <a
                href="#products"
                className="text-base text-purple-100 py-7 flex justify-center m-0 cursor-pointer mx-4 pr-9 hover:text-gray-darklight"
              >
                Products
              </a>
            </div>
          </div>
          <div className="flex px-0 xl:px-2 lg:w-5/6 xl:w-5/12 2xl:w-4/12 justify-center xl:justify-around items-center">
            {/* <a href = "https://app.vantagecircle.com/" className = "text-purple-100 flex justify-center items-center m-0 text-base mr-2 cursor-pointer">Sign In</a> */}
            <a href="/request-demo/" className="vc-btn-orange-v2 ml-3">
              Request a Demo
            </a>
            {/* <LangSelect /> */}
          </div>
        </div>
        {/* <div className = "flex lg:hidden"><LangSelect /></div> */}
        <div
          className="flex lg:hidden justify-center items-center"
          onClick={showmobileDropdown}
        >
          <div className="rounded-full bg-white p-1 cursor-pointer">
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                stroke-linejoin="round"
                strokewidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      <div
        className={
          mobileDropdown
            ? `overflow-x-hidden overflow-y-scroll absolute top-12 mt-1 md:mt-4 w-full left-0 flex flex-col justify-start items-start p-6 bg-white dropdown shadow-darkgray rounded-2xs`
            : "hidden"
        }
      >
        <div className="flex flex-col w-full"></div>
        <a href="#benefits" className="text-gray-250 my-3">
          Benefits
        </a>
        <a href="#clienttestimony" className="text-gray-250 my-3">
          Clients
        </a>
        <a href="#integrate" className="text-gray-250 my-3">
          Integrations
        </a>
        <a href="#products" className="text-gray-250 my-3">
          Products
        </a>
        <div className="w-full">
          <a href="/request-demo/" className="vc-btn-orange-v2 my-2">
            Request a Demo
          </a>
          {/* <a href = "https://app.vantagecircle.com/" className = "vc-btn-indigo-border-v2 my-2">Sign In</a> */}
          {/* <div className = "w-full flex place-content-center"><a href = "https://app.vantagecircle.com/" className = "my-2 w-full flex place-content-center text-gray-250 underline">Sign In</a></div> */}
        </div>
      </div>
    </div>
  )
}

export default GartnerNav
