// import React from "react";
// import { Link } from "gatsby";

// const NewHeader = () => {
//     return (
//         <div>
// 			<div className="container md:max-w-4xl lg:max-w-7xl mx-auto px-2 py-4 sm:px-6">
// 				<div className="flex justify-between items-center pb-2 md:justify-start md:space-x-10">
// 					<div className="flex justify-start lg:w-2/6">
// 						<div className="flex items-center">
// 						    <span className="sr-only">Vantage Circle</span>
// 						    <Link to="/">
//                                 <img className="h-8 w-auto sm:h-10 transform -translate-x-4 md:-translate-x-0 scale-75 md:scale-100" src="https://res.cloudinary.com/vantagecircle/image/upload/vantagecms/uploads/2020/08/vantage-circle-logo-compressed.png" alt="Vantage Circle Logo"  width="273" height="40"/>
//                             </Link>
// 						</div>
// 					</div>
// 				</div>
// 			</div>
//         </div>
//     );
// }

// export default NewHeader;
import React, { useState } from "react"

const NewHeader = () => {
  const [mobileDropdown, setmobileDropdown] = useState(false)
  const [mobileProductsOpen, setmobileProductsOpen] = useState(false)
  const [mobileResourcesOpen, setmobileResourcesOpen] = useState(false)

  const showmobileDropdown = () => {
    setmobileDropdown(!mobileDropdown)
  }

  const handleMobileProducts = () => {
    setmobileProductsOpen(!mobileProductsOpen)
    setmobileResourcesOpen(false)
  }

  const handleMobileResources = () => {
    setmobileResourcesOpen(!mobileResourcesOpen)
    setmobileProductsOpen(false)
  }

  return (
    <div className="w-full bg-indigo-100 shadow-darkgray flex place-content-center z-50 py-2">
      <div className="w-11/12 flex justify-between px-2 items-center">
        <div className="w-2/3 lg:w-1/3 py-3 lg:py-3">
          <div>
            <img
              className="w-auto vc-logo"
              src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/04/New-VC-Logo-invert.png"
              alt="Vantage Circle Logo"
              width="273"
              height="40"
            />
          </div>
        </div>
        <div className="hidden lg:flex justify-end items-center w-1/3 lg:w-11/12">
          <div className="flex px-0 xl:px-2 lg:w-5/6 xl:w-5/12 2xl:w-4/12 justify-center xl:justify-end items-center">
            {/* <a
              href="#req-demo"
              className="text-gray-100 bg-newOrange py-2 lg:px-2 xl:px-4 2xl:px-5 rounded mx-1 transition duration-500 ease-in-out transform hover:-translate-y-1 flex justify-center items-center lg:text-base xl:text-lg"
            >
              Request a Demo
            </a> */}
          </div>
        </div>
        <div
          className="flex lg:hidden justify-center items-center"
          onClick={showmobileDropdown}
        >
          <div className="rounded-full bg-white p-2 cursor-pointer">
            <svg
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                stroke-linejoin="round"
                strokewidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      <div
        className={
          mobileDropdown
            ? `overflow-x-hidden overflow-y-scroll absolute top-12 mt-1 md:mt-4 w-full left-0 flex flex-col justify-start items-start p-6 bg-white dropdown shadow-darkgray rounded-2xs z-50` +
              (mobileProductsOpen || mobileResourcesOpen ? " h-96" : "")
            : "hidden"
        }
      >
        <div className="w-full flex justify-center items-center">
          {/* <a href="#req-demo" className="w-full vc-btn-orange-v2 my-2">
            Request a Demo
          </a> */}
        </div>
      </div>
    </div>
  )
}

export default NewHeader
