import React from "react"

import HubspotForm from "react-hubspot-form"

//  const aireBackup = () => {
//    return (    
//    <>
//       <div
//       id="exit-intent"
//       className="popup-overlay modal w-full h-full top-0 left-0 flex items-center justify-center"
//     >
//       <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
//       <div className="exit-content relative modal-container bg-white w-11/12 md:w-8/12 md:max-w-md lg:max-w-6xl xl:max-w-4xl mx-auto rounded-xl shadow-lg z-50 overflow-y-auto">
//         <button
//           className="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50"
//           onClick={closepopup}
//         >
//           <svg
//             className="fill-current text-gray-500"
//             width="30"
//             height="30"
//             viewBox="0 0 18 18"
//           >
//             <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
//           </svg>
//         </button>
//         <div className="modal-content text-left relative overflow-hidden">
//           <div className="md:flex relative">
//             <div className="md:w-6/12 mob">
//               <img
//                 src="https://res.cloudinary.com/vantagecircle/image/upload/v1644995802/gatsbycms/uploads/2022/02/Emotional_connect.png"
//                 alt="Aire Redefine Your Recognition Program with AIRᵉ Quality Score"
//               />
//             </div>
//             <div className="md:w-8/12 p-10">
//               <p className="text-2xl md:text-3xl text-gray-500 font-bold" style={{marginTop: "15px"}}>
//                 Check if your current Rewards & Recognition Strategy is in Sync With the Global Benchmarks with a<br/> <span className="text-orange">Free Personalized Assessment.</span>
//               </p>
//               <p className="py-3">A detailed report will also be shared with you after the assessment</p>
//               <div className="rounded-sm w-11/12 md:w-9/12 mt-7">
//                 {/* <HubspotForm
//                   portalId="6153052"
//                   formId="9516ee95-0d36-4f9e-9340-2018a65ef260"
//                   onSubmit={() => console.log("Submit!")}
//                   onReady={form => console.log("Form ready!")}
//                   loading={<div>Loading...</div>}
//                 /> */}
//                 <a href="/tools/aire-calculator"  id="aire-link-btn" rel="noopener noreferrer" target="_blank" className="vc-btn-orange-v2 px-10">Take the AIRe Assessment</a>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </>
//   )
// }

const ExitIntentEs = ({ exitintent, closepopup }) => {
    return (
          <>
            <div
              id="exit-intent"
              className="popup-overlay modal w-full h-full top-0 left-0 flex items-center justify-center"
            >
              <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
              <div className="exit-content relative modal-container bg-white w-11/12 md:w-8/12 md:max-w-md lg:max-w-6xl xl:max-w-4xl mx-auto rounded-xl shadow-lg z-50 overflow-y-auto">
                <button
                  className="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50"
                  onClick={closepopup}
                >
                  <svg
                    className="fill-current text-gray-500"
                    width="30"
                    height="30"
                    viewBox="0 0 18 18"
                  >
                    <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                  </svg>
                </button>
                <div className="modal-content text-left p-5 md:p-10 relative overflow-hidden">
                  <div className="md:flex relative">
                    <div className="absolute -z-1 ebk-imgholder mob">
                      <div className="imgholder-width overflow-hidden inline-block">
                        <div className="imgholder-height bg-purple-50 transform origin-bottom-right"></div>
                      </div>
                    </div>
                    <div className="md:w-4/12 mob">
                      <img
                        src="https://res.cloudinary.com/vantagecircle/image/upload/w_800/gatsbycms/uploads/2022/09/spanish-guide.png"
                        alt="Elija la mejor programa de Recompensas y Reconocimiento que cumpla con todos tus requisitos"
						style={{maxWidth : "380px", marginLeft : "-63px"}}
                      />
                    </div>
                    <div className="text-center md:w-8/12 pt-10">
                      <p className="text-2xl md:text-3xl text-gray-500 font-bold">
						Elija la mejor programa de Recompensas y Reconocimiento
						que cumpla con todos tus requisitos
                      </p>
                      <p>Descarga la guía del comprador completa para obtener más
                      información.</p>
                      <div className="exit-intent-form bg-purple-50 p-5 rounded-sm w-11/12 md:w-9/12 mx-auto mt-7">
                        <HubspotForm
                          portalId="6153052"
                          formId="2d416beb-6d77-48ea-8cd1-42b11d9d5652"
                          onSubmit={() => console.log("Submit!")}
                          onReady={form => console.log("Form ready!")}
                          loading={<div>Loading...</div>}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
  )
}

export default ExitIntentEs
