import React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect } from 'react'
import HubspotForm from 'react-hubspot-form'

// import Modal from "@material-tailwind/react/Modal";
// import ModalHeader from "@material-tailwind/react/ModalHeader";
// import ModalBody from "@material-tailwind/react/ModalBody";
// import ModalFooter from "@material-tailwind/react/ModalFooter";
// import Button from "@material-tailwind/react/Button";

const ExitIntentFr = ({ exitintent, closepopup }) => {

    return (
       <>
        <div id="exit-intent" className="popup-overlay modal w-full h-full top-0 left-0 flex items-center justify-center" >
					<div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
					<div className="exit-content relative modal-container bg-white w-11/12 md:w-8/12 md:max-w-md lg:max-w-6xl xl:max-w-4xl mx-auto rounded-xl shadow-lg z-50 overflow-y-auto">
						<button className="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50" onClick = {closepopup}>
							<svg className="fill-current text-gray-500" width="30" height="30" viewBox="0 0 18 18">
								<path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
							</svg>
						</button>
						<div className="modal-content text-left p-5 md:p-10 relative overflow-hidden">
							<div className="md:flex relative">
								<div className="absolute -z-1 ebk-imgholder mob">
									<div className="imgholder-width overflow-hidden inline-block">
										{/* <div className="imgholder-height bg-purple-50 transform origin-bottom-right"></div> */}
									</div>
								</div>
								<div className="md:w-4/12 mob pt-8"><img src="https://res.cloudinary.com/vantagecircle/image/upload/w_280/gatsbycms/uploads/2021/12/FR.png" alt="Industry Report On Employee Engagement Strategies for 2021"/></div>
                                    <div className="text-center md:w-8/12 pt-10">
                                        <p className="text-2xl md:text-3xl text-gray-500 font-bold px-5">
                                        Intégrez les meilleures pratiques pour créer une culture de travail engageante.
                                        </p>
                                        <p className = "px-5">Réservez une démo dès aujourd'hui pour la plate-forme <br/> complète de Vantage Circle.</p>
                                        <div className = "w-full justify-start flex">
                                        <div className = "w-full justify-start flex">
                                            <div className = "w-full flex justify-center py-6"><div className = "w-1/2"><a href = "https://www.vantagecircle.com/fr/planifier-demo/"><button className = "vc-colored-btn">COMMENCER</button></a></div></div>
                                        </div>
                                        </div>
                                    </div>
						
							</div>
						</div>   
					</div>
		</div>
       </>
    )
}

export default ExitIntentFr
