import React, { useEffect, useState } from "react"
import HelloBar from "./HelloBar"
import HeaderEn from "./HeaderSelect/HeaderEn"
import HeaderMultiLang from "./HeaderSelect/HeaderMultiLang"
import HeaderTopEn from "./HeaderSelect/HeaderTopEn"
import HeaderTopMultiLang from "./HeaderSelect/HeaderTopMultiLang"
// import LangSelect from "./LangSelect"
// import { useI18next } from "gatsby-plugin-react-i18next"
import { useLanguages } from "../hooks/useLanguages"

const Header = ({ pageLanguage, pathname }) => {
  const [currLanguage, originalPathObj] = useLanguages()

  const excludedPagesForHelloBar = [
    "/employee-rewards-and-recognition-program-v2/",
    "/employee-rewards-and-recognition-platform-v2/",
    "/employee-rewards-and-recognition-program-v3/",
    "/employee-rewards-and-recognition-platform-v3/",
  ]

  const handleHeader = () => {
    if (pageLanguage === "en" || currLanguage === "en") {
      return <HeaderEn />
    } else return <HeaderMultiLang pageLanguage={pageLanguage} />
  }

  const handleHelloBar = () => {
    if (!excludedPagesForHelloBar.includes(pathname)) {
      return <HelloBar />
      // return null
    } else return null
  }

  const [helloBarMessage, setHelloBarMessage] = useState(true)

  useEffect(() => {
    if (typeof window !== "undefined") {
      var action = sessionStorage.getItem(`helloBar`)
      var helloBarMessage = action === `false` ? false : true
      setHelloBarMessage(helloBarMessage)
    }
  }, [])

  return (
    <>
      {/* <div className="border-b-2 border-gray-100 mob">
        <div className="container md:max-w-4xl lg:max-w-7xl mx-auto px-4 sm:px-6 flex justify-between items-center py-2 md:justify-start md:space-x-10">
          <div className="hidden lg:flex items-center justify-end md:flex-1">
            <div className="space-x-5 hidden sm:flex flex-shrink-0 ml-3 items-center py-1">
              {handleTopHeader()}
              {originalPathObj == "/start-free-trial/" ? null : <LangSelect />}
            </div>
          </div>
        </div>
      </div> */}
      <header
        className={"sticky bg-indigo-100"}
        // style={{ overflow: "overlay" }}
      >
        {(currLanguage === "en" || currLanguage === "fr") ? helloBarMessage === true ? handleHelloBar() : null : null}
        {/* {helloBarMessage === true ? handleHelloBar() : null} */}
        {handleHeader()}
      </header>
    </>
  )
}

export default Header
