import React, { useState } from "react"
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import HubspotForm from 'react-hubspot-form'

const RnRnav = ({ navProperty }) => {
  const [mobileDropdown, setmobileDropdown] = useState(false)

  const showmobileDropdown = () => {
    setmobileDropdown(!mobileDropdown)
  }
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  return (
    <>
    <div className={`w-full ${navProperty.bgColor} ${navProperty.shadow} flex place-content-center z-50 py-2`}>
      <div className="max-w-7xl flex justify-between px-2 items-center">
        <div className="w-full lg:w-1/3 py-3 lg:py-3 flex">
            <a className="flex items-start justify-items-start" href="/">
                <img
                className={`w-auto vc-logo transform ${navProperty.logoSize}`}
                src={`https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/${navProperty.logo}`}
                alt="Vantage Circle Logo"
                width="250"
                height="40"
                />
            </a>
            <img className="hrci-logo" src="https://res.cloudinary.com/vantagecircle/image/upload/v1724674278/gatsbycms/uploads/2024/08/logo_HRCI.png" alt="HRCI logo" width="134" height="45"/>
        </div>
        <div className="hidden lg:flex justify-end items-center w-1/3 lg:w-2/3">
          <div className="px-5 flex justify-items-center">
            <div>
              <a
                href="#home"
                className="text-base text-gray-250 py-7 flex justify-center m-0 cursor-pointer mx-4 pr-9 hover:text-opacity-80"
              >
                Home
              </a>
            </div>
            <div>
              <a
                href="#about"
                className="text-base text-gray-250 py-7 flex justify-center m-0 cursor-pointer mx-4 pr-9 hover:text-opacity-80"
              >
                About
              </a>
            </div>
            <div>
              <a
                href="#masterclass"
                className="text-base text-gray-250 py-7 flex justify-center m-0 cursor-pointer mx-4 pr-9 hover:text-opacity-80"
              >
                Masterclass
              </a>
            </div>
            <div>
              <a
                href="https://www.vantagecircle.com/webinars/"
                className="text-base text-gray-250 py-7 flex justify-center m-0 cursor-pointer mx-4 pr-9 hover:text-opacity-80"
              >
                Webinars
              </a>
              
            </div>
            <div className="flex justify-center items-center">
            <button onClick={onOpenModal} className="text-gray-100 bg-newOrange py-2 lg:px-2 xl:px-6 rounded-full mx-1 transition duration-500 ease-in-out transform hover:-translate-y-1 flex justify-center items-center lg:text-base xl:text-lg">
                Register for Event
            </button>
            </div>
          </div>
          
        </div>
        {/* <div className = "flex lg:hidden"><LangSelect /></div> */}
        <div
          className="flex lg:hidden justify-center items-center"
          onClick={showmobileDropdown}
        >
          <div className="rounded-full bg-white p-1 cursor-pointer">
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      <div
        className={
          mobileDropdown
            ? `overflow-x-hidden overflow-y-scroll absolute top-12 mt-1 md:mt-4 w-full left-0 flex flex-col justify-start items-start p-6 bg-white dropdown shadow-darkgray rounded-2xs`
            : "hidden"
        }
      >
        <div className="flex flex-col w-full"></div>
        <a href="#home" className="text-gray-250 my-3">
          Home
        </a>
        <a href="#about" className="text-gray-250 my-3">
          About
        </a>
        <a href="#masterclass" className="text-gray-250 my-3">
          Masterclass
        </a>
        <a href="https://www.vantagecircle.com/webinars/" className="text-gray-250 my-3">
          Webinars
        </a>
        <div className="w-full">
          <button onClick={onOpenModal} className="text-gray-100 bg-newOrange py-2 lg:px-2 xl:px-6 rounded-full px-3 flex justify-center items-center lg:text-base xl:text-lg">
            Register for Event
          </button>
        </div>
      </div>
    </div>
    <Modal open={open} onClose={onCloseModal} center>
        <div className="form-section">
            <h2 className="form-title mt-2 mb-10 text-2xl font-semibold text-gray-900 sm:text-3xl pb-2">Fill up to register now</h2>
            <HubspotForm
                portalId='6153052'
                formId='3b9628df-7260-4e07-8f08-c59e7d6e3335'
                onSubmit={() => console.log('Submit!')}
                onReady={(form) => console.log('Form ready!')}
                loading={<div>Loading...</div>}
            />
            {/* <div className="notetext text-center font-normal text-sm">We safeguard your personal information in accordance with our <a href="/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a></div> */}
        </div>
    </Modal>
    </>

  )
}

export default RnRnav
