import React, { useState, useEffect } from "react"
import { Transition, Listbox } from "@headlessui/react"
import { useLanguages } from "../hooks/useLanguages"
import { Link, navigate } from "gatsby"

const LangSelect = ({ displayLang = true }) => {
  const [
    currLanguage,
    languagesArr,
    languageandFlags,
    originalPathsObj,
    changeLanguage,
  ] = useLanguages()

  const [selectedLanguage, setSelectedLanguage] = useState()

  const homeUrls = [
    "localhost:8000",
    "localhost:9000",
    "https://uat-vc.netlify.app/",
    "https://www.vantagecircle.com/",
    "localhost:8001",
  ]

  const handleChange = e => {
    changeLanguage(e)
    sessionStorage.setItem(`helloBar`, true)
    setSelectedLanguage(e)
    if (e == "en") {
      window.location.replace("/")
    } else if (e !== currLanguage) {
      window.location.replace(`/${e}`)
    }
  }

  //code to redirect url on initial render
  useEffect(() => {
    if (typeof window !== undefined) {
      let currentLangInLocalStorage = window.localStorage.getItem(
        "languageSelected"
      )
      setSelectedLanguage(currentLangInLocalStorage)
      if (
        currentLangInLocalStorage !== "en" &&
        homeUrls.includes(window.location.href)
      ) {
        navigate(`/${currentLangInLocalStorage}/`)
      }
    }
  }, [])

  return (
    <>
      <Listbox as="div"className="space-y-1" value={currLanguage} onChange={handleChange}>
        {({ open }) => (
          <>
            <div className="relative">
              <span className="inline-block w-full">
                <Listbox.Button
                  className={`${
                    displayLang
                      ? "shadow-sm cursor-pointer relative p-2 md:px-3 text-left focus:outline-none bg-purple-800 rounded-full transition ease-in-out duration-150 sm:text-sm"
                      : "shadow-sm cursor-pointer relative p-2 text-left focus:outline-none bg-purple-800 rounded-full transition ease-in-out duration-150 sm:text-sm"
                  }`}
                >
                  {/* <span
                    className={`${
                      (displayLang && currLanguage !== "en")
                        ? "absolute inset-y-0 right-1 hidden md:flex items-center pr-2 uppercase text-white"
                        : "hidden"
                    }`}
                  >
                    {currLanguage}
                  </span> */}
                  <span>
                    <img className="h-5 w-5 text-gray-400" src={languageandFlags[currLanguage]} style={{ minWidth: "20px" }} />
                    {/* <svg
                      className="h-5 w-5 text-gray-400"
                      viewBox="0 0 24 24"
                      fill="#fff"
                      stroke="#fff"
                    >
                      <path d="M12.02 0c6.614.011 11.98 5.383 11.98 12 0 6.623-5.376 12-12 12-6.623 0-12-5.377-12-12 0-6.617 5.367-11.989 11.981-12h.039zm3.694 16h-7.427c.639 4.266 2.242 7 3.713 7 1.472 0 3.075-2.734 3.714-7m6.535 0h-5.523c-.426 2.985-1.321 5.402-2.485 6.771 3.669-.76 6.671-3.35 8.008-6.771m-14.974 0h-5.524c1.338 3.421 4.34 6.011 8.009 6.771-1.164-1.369-2.059-3.786-2.485-6.771m-.123-7h-5.736c-.331 1.166-.741 3.389 0 6h5.736c-.188-1.814-.215-3.925 0-6m8.691 0h-7.685c-.195 1.8-.225 3.927 0 6h7.685c.196-1.811.224-3.93 0-6m6.742 0h-5.736c.062.592.308 3.019 0 6h5.736c.741-2.612.331-4.835 0-6m-12.825-7.771c-3.669.76-6.671 3.35-8.009 6.771h5.524c.426-2.985 1.321-5.403 2.485-6.771m5.954 6.771c-.639-4.266-2.242-7-3.714-7-1.471 0-3.074 2.734-3.713 7h7.427zm-1.473-6.771c1.164 1.368 2.059 3.786 2.485 6.771h5.523c-1.337-3.421-4.339-6.011-8.008-6.771" />
                    </svg> */}
                  </span>
                </Listbox.Button>
              </span>

              <Transition show={open} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0" className="absolute mt-1 w-fit rounded bg-white shadow-md z-30">
                <Listbox.Options
                  static
                  className="max-h-72 rounded text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
                >
                  {languagesArr.map(lng => (
                    <Listbox.Option key={lng} value={lng}>
                      {({ selected, active }) => (
                        <a to={lng} language={lng}>
                          <div
                            className={`${
                              active
                                ? "text-purple-200 bg-purple-50"
                                : "text-purple-200"
                            } cursor-pointer  select-none relative p-2 flex uppercase `}
                          >
                             <img src={languageandFlags[lng]} height="30" width="30" />
                            {/* <span
                              className={`${
                                selected
                                  ? `font-bold`
                                  : `font-normal gtm-lan-button-change-${lng}`
                              } block`}
                            >
                              {lng}
                            </span> */}


                            {/* {selected && (
                              <span
                                className={`${
                                  active ? "text-purple-200" : "text-purple-200"
                                } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                              >
                                <svg
                                  className="h-5 w-5"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </span>
                            )} */}
                          </div>
                        </a>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </>
  )
}

export default LangSelect
