import React, { useState, useEffect } from 'react'
import { Link } from "gatsby";

const RegistrationHeader = () => {
    const [isOpen1, setIsOpen] = useState(false)
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 50);
        });
    }, []);

    return (
        <div>
			<div className="w-full mx-auto flex place-content-center px-2 py-4 sm:px-6">
				<div className="flex justify-between items-center w-full md:w-11/12 pb-2 xl:px-8 2xl:px-12 md:justify-start md:space-x-10">
					<div className="flex justify-start lg:w-2/6">
						<div className="flex items-center">
						    <span className="sr-only">Vantage Circle</span>
                            <a href="https://www.vantagecircle.com/">
						        <img className="h-8 w-auto sm:h-10 transform -translate-x-4 md:-translate-x-0 scale-75 md:scale-100" src="https://res.cloudinary.com/vantagecircle/image/upload/w_274/gatsbycms/uploads/2023/07/new-vc-logo.png" alt="Vantage Circle Logo"  width="274" height="40"/>
                            </a>
                        </div>
					</div>
					<div className="right-3.5 sm:right-20 md:right-28 absolute -mr-2 -my-2 xl:hidden">
						<button type="button" onClick={() => setIsOpen(!isOpen1)} className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-purple-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500">
							<span className="sr-only">Open menu</span>
							<svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
							</svg>
						</button>
					</div>

					<div className="xl:flex items-center justify-end w-4/6 space-x-10 hidden">
                        
						<a href="#about" className="text-lg font-normal text-gray-500 hover:text-purple-900">
						About Vantage Point
						</a>
                        
                        <a href="#guestspeakers" className="text-lg font-normal text-gray-500 hover:text-purple-900">
						About Guest Speakers
						</a>
                    
						<a href="#hosts" className="text-lg font-normal text-gray-500 hover:text-purple-900">
						About Hosts
						</a>
                        
                        <a href="#faqs" className="text-lg font-normal text-gray-500 hover:text-purple-900">
						FAQs
						</a>
					</div>
				</div>
			</div>
            <div className={isOpen1 ? "" : "hidden"}>              
                <div className="fixed z-50 top-0 inset-x-0 transition transform origin-top-right xl:hidden shadow-nav ">
                    <div className="bg-white divide-y-2 divide-purple-50">
                        <div className="pt-5 pb-6 px-4">
                            <div className="flex items-center justify-between">
                                <div>
                                    <img className="h-8 w-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/vantagecms/uploads/2020/08/vantage-circle-logo-compressed.png" alt="Vantage Circle" />
                                </div>
                                <div className="-mr-2">
                                    <button type="button" onClick={() => setIsOpen(!isOpen1)} className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-purple-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500">
                                        <span className="sr-only">Close menu</span>
                                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div className="p-2 mt-3 max-h-96 overflow-y-scroll">
                                <nav className="grid gap-y-8 mobile-menu">
                                    {/* Mobile Menu starts */}
                                    <div className="content">
                                        <div className="acc">
                                            
                                            <a href="#about" className="-m-3 mb-2 p-3 flex items-center">
                                                <span className="flex-1 text-base font-normal text-gray-900">
                                                    About Vantage Point
                                                </span>
                                            </a>

                                            <a href="#guestspeakers" className="-m-3 mb-2 p-3 flex items-center">
                                                <span className="flex-1 text-base font-normal text-gray-900">
                                                    About Guest Speakers
                                                </span>
                                            </a>
                                        
                                            <a href="#hosts" className="-m-3 mb-2 p-3 flex items-center">
                                                <span className="flex-1 text-base font-normal text-gray-900">
                                                    About Hosts
                                                </span>
                                            </a>

                                            <a href="#faqs" className="-m-3 mb-2 p-3 flex items-center">
                                                <span className="flex-1 text-base font-normal text-gray-900">
                                                    FAQs
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    {/* Mobile Menu ends */}
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
			</div>
        </div>
    );
}

export default RegistrationHeader
