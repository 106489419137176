import React, { useEffect, useState } from "react"
import Trans from "../components/Trans"
import { useLanguages } from "../hooks/useLanguages"
import useGeoLocation from "../hooks/useGeoLocation"

const helloBarLinks = {
  "NA": {
    "en": "https://www.vantagecircle.com/case-study/wipro-case-study-vantage-circle/"
  },
  "default":{
  "en": "https://www.vantagecircle.com/case-study/wipro-case-study-vantage-circle/",
  "fr": "https://www.vantagecircle.com/fr/guide-de-lacheteur-pour-une-plateforme-de-sondage-sur-lengagement-des-employes/"
  }
}

const NAHelloBars = [
  "United States", "Canada"
]

function HelloBar() {
  const [scroll1, setScroll1] = useState(false)
  const [showHellobar, setShowHelloBar] = useState(true)
  const [currLanguage, originalPathObj] = useLanguages()
  const [currentCountry] = useGeoLocation()


  const handleScroll = () => {
    if (!showHellobar) return
    setScroll1(window.scrollY > 200)
  }

  const isNAhelloBar = (NAHelloBars.includes(currentCountry.country) && currLanguage == "en")
  const helloBarCTALink = (NAHelloBars.includes(currentCountry.country) && currLanguage == "en") ? helloBarLinks["NA"][currLanguage] : helloBarLinks["default"][currLanguage]

  //For copying superscript - AIRᵉ

  useEffect(() => {
    console.log("country", currentCountry.country);
    // return
    if (typeof window !== "undefined") {
      if (showHellobar) {
        sessionStorage.setItem(`helloBar`, true)
      }
      setShowHelloBar(true)
      window.addEventListener("scroll", handleScroll)
    }
    return () => window.removeEventListener("scroll", handleScroll)
  }, [scroll1])

  return (
    <>
      <div
        id="helloBar"
        className={
          scroll1
            ? "barslideDown sticky-nav bg-white mob"
            : "barslideUp sticky-nav min mob"
        }
      >
        <div className="hellobar-text md:text-lg text-center py-2 pl-3 pr-10 md:p-0 h-auto md:h-14  md:flex md:items-center md:justify-center md:align-middle">
          <span className="text-normal mr-1  text-orange font-bold md:my-6 pt-1">
            {
              isNAhelloBar ? <Trans>helloBarNA1</Trans> : <Trans>hellobar1</Trans>
            }
          </span>
          <span
            className="text-normal mr-1 md:my-6 pt-1 text-g "
            target="_blank"
          >
            {
              isNAhelloBar ? <Trans>helloBarNA2</Trans> : <Trans>hellobar2</Trans>
            }
          </span>
          <a
            href={helloBarCTALink}

            className="tracking-normal font-bold underline  md:my-6 md:pt-1 ml-2 "
          >
            {
              isNAhelloBar ? <Trans>hellobarctaNA</Trans> : <Trans>hellobarcta</Trans>
            }
          </a>

          {/* <span className="tracking-normal md:mt-6 pt-1 mr-1">
            <Trans>hellobar3</Trans> &nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <span className="tracking-normal md:mt-6 pt-1 mr-1">
            <Trans>hellobar4</Trans> &nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <span className="tracking-normal mr-4 md:mt-6 pt-1">
            <Trans>hellobar5</Trans> &nbsp;&nbsp;&nbsp;&nbsp;
          </span> */}
          {/* <a
            id="hellobar-cta-vc"
            className="hellobar-btn hellobar-cta cta-wiggle px-4 py-1 text-bold"
            href="https://www.vantagecircle.com/resources/rewards-and-recognition-buyers-guide/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Trans>hellobarcta</Trans>
          </a> */}
          <button
            className="hellobar-closebtn"
            onClick={() => closeHelloBar(setShowHelloBar)}
          >
            +
          </button>
        </div>
      </div>
    </>
  )
}

function closeHelloBar(setShowHelloBar) {
  if (typeof window !== "undefined") {
    document.getElementById(`helloBar`).classList.add(`barslideUp`)
    document.getElementById(`helloBar`).classList.remove(`barslideDown`)
    // document.getElementById(`rose`).classList.remove(`ishello`)
    // document.getElementById(`rose`).classList.add(`nonhello`)
    setShowHelloBar(false)
  }
  sessionStorage.setItem(`helloBar`, false)
}

export default HelloBar
