export const IsTheElementOnScreen = (e) => {
    let checkElement  = e.getBoundingClientRect()
    return(checkElement.top >= 0 &&
       checkElement.left >= 0 &&
       checkElement.right <= (window.innerWidth || document.documentElement.clientWidth) &&
       checkElement.bottom <= (window.innerHeight || document.documentElement.clientHeight))
}

export const preloadImages = (imgarray) => {
    imgarray.forEach((url) => {
      const img = new Image();
      img.src = url;
    });
  };

export const handleLinkClick = (e, url) => {
    e.preventDefault();
    window.open(url, "_blank", "noopener,noreferrer");
};