export const topHeaderLinks = {
  en: {
    home: "/",
    "about-us": "/about-us/",
    careers: "/careers/",
    "contact-us": "/contact-us/",
    "download-app": "/download-app/",
  },
  es: {
    home: "/es/",
    "about-us": "/es/sobre-nosotros/",
    "contact-us": "/es/contactenos/",
    "download-app": "/es/descargar-aplicacion/",
  },
  fr: {
    home: "/fr/",
    "about-us": "/fr/a-propos-de-nous/",
    "contact-us": "/fr/contactez-nous/",
    "download-app": "/fr/application-telecharger/",
  },
  de: {
    home: "/de/",
    "about-us": "/de/uber-uns/",
    "contact-us": "/de/kontaktiere-uns/",
    "download-app": "/de/app-herunterladen/",
  },
  // 'nl' : {
  //     'home' : '/nl/',
  //     'about-us' : '/nl/over-ons/',
  //     'contact-us' : '/nl/contacteer-ons/',
  //     'download-app': '/nl/app-downloaden/',
  // },
  // 'pt' : {
  //     'home' : '/pt/',
  //     'about-us' : '/pt/sobre-nos/',
  //     'contact-us' : '/pt/contate-nos/',
  //     'download-app': '/pt/baixe-o-app/',
  // },
}

export const headerLinks = {
  en: {
    "vantage-circle": "/",
    "vantage-rewards": "/solutions/rewards-recognition/",
    "vantage-perks": "/solutions/employee-discount/",
    "vantage-pulse": "/solutions/employee-survey/",
    "employee-engagement": "/employee-engagement-software/",
    integrations: "/integrations/",
    corporates: "/corporates/",
    "case-study": "/case-study/",
    "ingram-micro": "/case-study/ingram-micro/",
    "accor-hotels": "/case-study/accor-hotels/",
    "tata-motors": "/case-study/tata-motors/",
    webinars: "/webinars/",
    "hr-academy": "/hr-academy/",
    "industry-reports": "/hr-academy/industry-reports/",
    aire: "/hr-academy/aire-framework-employee-recognition/",
    demo: "/request-demo/",
    pricing: "/pricing/",
    partners: "/strategic-partnership/",
    resources: "/resources/",
  },
  fr: {
    "vantage-circle": "/fr/",
    "vantage-rewards":
      "/fr/logiciel-engagement-employes/recompenses-reconnaissance/",
    "vantage-perks": "/fr/logiciel-engagement-employes/employe-avantages/",
    "vantage-pulse": "/fr/logiciel-engagement-employes/employe-sondage/",
    "employee-engagement": "/fr/logiciel-engagement-employes/",
    integrations: "/fr/solutions-dintegration/",
    pricing: "/fr/tarification/",
    coorporates: "/fr/nos-entreprises-clientes/",
    partners: "/fr/partenariat-strategique/",
    demo: "/fr/planifier-demo/",
    blog: "https://www.vantagecircle.com/fr/blog/",
  },
  es: {
    "vantage-circle": "/es/",
    "vantage-rewards":
      "/es/software-participacion-empleados/reconocimiento-recompensas/",
    "vantage-perks":
      "/es/software-participacion-empleados/beneficios-empleados/",
    "vantage-pulse": "/es/software-participacion-empleados/encuesta-empleados/",
    "employee-engagement": "/es/software-participacion-empleados/",
    integrations: "/es/integracion/",
    pricing: "/es/precios/",
    coorporates: "/es/clientes/",
    partners: "/es/asociacion-estrategica/",
    demo: "/es/programe-demostracion/",
    blog: "https://www.vantagecircle.com/es/blog/",
    resources: "/es/recursos/",
  },
  de: {
    "vantage-circle": "/de/",
    "vantage-rewards": "/de/alles-in-einem/mitarbeiter-belohnungen/",
    "vantage-perks": "/de/alles-in-einem/miarbeiterrabatt/",
    "vantage-pulse": "/de/alles-in-einem/mitarbeiterpuls/",
    "employee-engagement": "/de/alles-in-einem/",
    integrations: "/de/integrationslosungen/",
    pricing: "/de/preisgestaltung/",
    coorporates: "/de/corporate-kunden/",
    partners: "/de/strategischer-partner/",
    demo: "/de/demo-buchen/",
    blog: "https://www.vantagecircle.com/de/blog/",
  },
  //   nl: {
  //     "vantage-circle": "/nl/",
  //     "vantage-rewards":
  //       "/nl/software-voor-werknemersbetrokkenheid/werknemersbeloningen/",
  //     "vantage-perks":
  //       "/nl/software-voor-werknemersbetrokkenheid/werknemerskorting/",
  //     "vantage-pulse":
  //       "/nl/software-voor-werknemersbetrokkenheid/medewerkersonderzoek/",
  //     "employee-engagement": "/nl/software-voor-werknemersbetrokkenheid/",
  //     integrations: "/nl/integraties/",
  //     pricing: "/nl/prijzen/",
  //     coorporates: "/nl/zakelijke/",
  //     partners: "/nl/strategisch-partnerchap/",
  //     demo: "/nl/bedankpagina-demo/",
  //     blog: "https://blog.vantagecircle.com/nl/",
  //   },
  //   pt: {
  //     "vantage-circle": "/pt/",
  //     "vantage-rewards":
  //       "/pt/software-de-engajamento-de-funcionarios/recompensas-reconhecimento/",
  //     "vantage-perks":
  //       "/pt/software-de-engajamento-de-funcionarios/desconto-de-funcionario/",
  //     "vantage-pulse":
  //       "/pt/software-de-engajamento-de-funcionarios/pulso-do-funcionario/",
  //     "employee-engagement": "/pt/software-de-engajamento-de-funcionarios/",
  //     integrations: "/pt/integracao",
  //     pricing: "/pt/precos",
  //     coorporates: "/pt/empresas",
  //     partners: "/pt/parceria-estrategica",
  //     demo: "/pt/solicitar-demonstracao",
  //     blog: "https://blog.vantagecircle.com/pt/",
  //   },
}
