import React, { useEffect, useState } from "react"
import { Menu, Transition } from "@headlessui/react"
import { headerLinks } from "../../../static/headerLinks"
import { useLanguages } from "../../hooks/useLanguages"
import Trans from "../../components/Trans"
import LangSelect from "../LangSelect"
import { languageObj } from "../../../locales/translations"

const HeaderMultiLang = ({ pageLanguage }) => {
  const [isOpen1, setIsOpen] = useState(false)
  const [scroll, setScroll] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50)
    })
    return () =>
      window.removeEventListener("scroll", () => {
        setScroll(window.scrollY > 50)
      })
  }, [])

  const getUrl = url => {
    if (pageLanguage === undefined) return
    return headerLinks[pageLanguage][url]
  }

  const [currLanguage] = useLanguages()
  console.log(currLanguage)

  return (
    <div>
      <div className="container md:max-w-4xl lg:max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <a href={getUrl("vantage-circle")}>
              <span className="sr-only">Vantage Circle</span>
              <img
                className="h-8 w-auto sm:h-10"
                src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/04/New-VC-Logo-invert.png"
                alt="Vantage Circle Logo"
                width="273"
                height="40"
              />
              {/* <img
                className="w-auto vc-logo"
                src="https://res.cloudinary.com/vantagecircle/image/upload/v1671014736/gatsbycms/uploads/2022/12/vc-xmas-logo.png"
                alt="Vantage Circle Logo"
                width="273"
                height="40"
              /> */}
            </a>
          </div>
          <div className="flex right-3.5 sm:right-20 md:right-28 absolute -mr-2 -my-2 lg:hidden">
            <LangSelect displayLang={false}/>
            <button
              type="button"
              onClick={() => setIsOpen(!isOpen1)}
              className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-purple-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500"
            >
              <span className="sr-only">Open menu</span>
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>

          <div className="lg:flex items-center justify-end md:flex-1 lg:w-0 space-x-10 hidden">
            <Menu>
              {({ open }) => (
                <>
                  <div className="relative">
                    <Menu.Button
                      type="button"
                      className="group rounded-md inline-flex items-center text-base font-normal focus:outline-none focus:none"
                    >
                      <span className="text-base font-normal text-purple-100 hover:text-gray-darklight">
                        <Trans pageLanguage={pageLanguage}>Solutions</Trans>
                      </span>
                      <svg
                        className="h-5 w-5 text-purple-100 hover:text-gray-darklight"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="#fff"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </Menu.Button>
                    <Transition
                      show={open}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <div
                        className={
                          scroll
                            ? "lg:-translate-y-4 transform div-wrapper1"
                            : "lg:translate-y-6 transform div-wrapper1"
                        }
                      >
                        <Menu.Items
                          static
                          className="container div-wrapper2 focus:outline-none focus:none"
                        >
                          <div className="rounded-lg shadow-lg overflow-hidden">
                            <div className="relative flex bg-white px-5 py-6 sm:gap-8 sm:p-8">
                              <div className="flex pt-5">
                                <div className="flex flex-wrap pr-4">
                                  <Menu.Item>
                                    <a
                                      href={getUrl("vantage-rewards")}
                                      className="cursor-pointer h-28  lg:w-1/2 p-3 flex items-start rounded-lg hover:bg-purple-50"
                                    >
                                      <img
                                        src="https://res.cloudinary.com/vantagecircle/image/upload/w_30/vantagecms/uploads/2020/08/vantagerewards-icon.png"
                                        alt="Vantage Rewards"
                                        width="30"
                                        height="30"
                                      />
                                      <div className="ml-4">
                                        <p className="text-lg font-bold text-gray-500 mb-0">
                                          <Trans pageLanguage={pageLanguage}>
                                            Vantage Rewards
                                          </Trans>
                                        </p>
                                        <p className="mt-1 text-base text-gray-200 mb-0">
                                          <Trans pageLanguage={pageLanguage}>
                                            Employee Rewards and Recognition.
                                          </Trans>
                                        </p>
                                      </div>
                                    </a>
                                  </Menu.Item>

                                  <Menu.Item>
                                    <a
                                      href={getUrl("vantage-perks")}
                                      className="cursor-pointer h-28 lg:w-1/2 p-3 flex items-start rounded-lg hover:bg-purple-50"
                                    >
                                      <img
                                        src="https://res.cloudinary.com/vantagecircle/image/upload/w_30/vantagecms/uploads/2020/08/vantageperks-icon.png"
                                        alt="Vantage Perks"
                                        width="30"
                                        height="30"
                                      />
                                      <div className="ml-4">
                                        <p className="text-lg font-bold text-gray-500 mb-0">
                                          <Trans pageLanguage={pageLanguage}>
                                            Vantage Perks
                                          </Trans>
                                        </p>
                                        <p className="mt-1 text-base text-gray-200 mb-0">
                                          <Trans pageLanguage={pageLanguage}>
                                            Employee Discount and Benefits.
                                          </Trans>
                                        </p>
                                      </div>
                                    </a>
                                  </Menu.Item>
                                  <Menu.Item>
                                    <a
                                      href={getUrl("vantage-pulse")}
                                      className="cursor-pointer -mt-7 h-28 lg:w-1/2 p-3 flex items-start rounded-lg hover:bg-purple-50"
                                    >
                                      <img
                                        src="https://res.cloudinary.com/vantagecircle/image/upload/w_30/vantagecms/uploads/2020/08/vantagepulse-icon.png"
                                        alt="Vantage Pulse"
                                        width="30"
                                        height="30"
                                      />
                                      <div className="ml-4">
                                        <p className="text-lg font-bold text-gray-500 mb-0">
                                          <Trans pageLanguage={pageLanguage}>
                                            Vantage Pulse
                                          </Trans>
                                        </p>
                                        <p className="mt-1 text-base text-gray-200 mb-0">
                                          <Trans pageLanguage={pageLanguage}>
                                            Employee Survey and Feedback.
                                          </Trans>
                                        </p>
                                      </div>
                                    </a>
                                  </Menu.Item>
                                  <Menu.Item>
                                    <a
                                      href="https://www.vantagefit.io/"
                                      target="_blank"
                                      rel="noreferrer"
                                      className="cursor-pointer -mt-7 h-28 lg:w-1/2 p-3 flex items-start rounded-lg hover:bg-purple-50"
                                    >
                                      <img
                                        src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/06/vantagefit-logo.png"
                                        alt="Vantage Fit"
                                        width="30"
                                        height="30"
                                      />
                                      <div className="ml-4">
                                        <p className="text-lg font-bold text-gray-500 mb-0">
                                          <Trans pageLanguage={pageLanguage}>
                                            Vantage Fit
                                          </Trans>
                                        </p>
                                        <p className="mt-1 text-base text-gray-200 mb-0">
                                          <Trans pageLanguage={pageLanguage}>
                                            Employee Health and Wellness.
                                          </Trans>
                                        </p>
                                      </div>
                                    </a>
                                  </Menu.Item>
                                </div>
                                <div className="border-l-2 border-gray-100 pl-5">
                                  <Menu.Item>
                                    <a
                                      href={getUrl("employee-engagement")}
                                      className="cursor-pointer h-32 w-full p-3 rounded-lg hover:bg-purple-50 flex"
                                    >
                                      <div className="ml-4">
                                        <p className="text-lg font-bold text-gray-500 mb-0">
                                          <Trans pageLanguage={pageLanguage}>
                                            All-in-One
                                          </Trans>
                                        </p>
                                        <p className="mt-1 text-base text-gray-200 mb-0">
                                          <Trans pageLanguage={pageLanguage}>
                                            Boost employee engagement with our
                                            AI-powered solutions.
                                          </Trans>
                                        </p>
                                      </div>
                                    </a>
                                  </Menu.Item>

                                  <Menu.Item>
                                    <a
                                      href={getUrl("integrations")}
                                      className="cursor-pointer h-32 w-full p-3 rounded-lg hover:bg-purple-50 flex"
                                    >
                                      <div className="ml-4">
                                        <p className="text-lg font-bold text-gray-500 mb-0">
                                          <Trans pageLanguage={pageLanguage}>
                                            Integrations
                                          </Trans>
                                        </p>
                                        <p className="mt-1 text-base text-gray-200 mb-0">
                                          <Trans pageLanguage={pageLanguage}>
                                            Seamless integrations with your
                                            existing HCM/HRIS platform.
                                          </Trans>
                                        </p>
                                      </div>
                                    </a>
                                  </Menu.Item>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Menu.Items>
                      </div>
                    </Transition>
                  </div>
                </>
              )}
            </Menu>
            <Menu>
              <a
                href={getUrl("pricing")}
                className="text-base font-normal text-purple-100 hover:text-gray-darklight"
              >
                <Trans pageLanguage={pageLanguage}>Pricing</Trans>
              </a>
            </Menu>
            <Menu>
              <a
                href={getUrl("coorporates")}
                className="text-base font-normal text-purple-100 hover:text-gray-darklight"
              >
                <Trans pageLanguage={pageLanguage}>Clients</Trans>
              </a>
            </Menu>
            {currLanguage === `es` ? (
            <Menu>
              <a
                href={getUrl("resources")}
                className="text-base font-normal text-purple-100 hover:text-gray-darklight"
              >
                <Trans pageLanguage={pageLanguage}>Resources</Trans>
              </a>
            </Menu>
            ) : null }
            <Menu>
              <a
                href={getUrl("partners")}
                className="text-base font-normal text-purple-100 hover:text-gray-darklight"
              >
                <Trans pageLanguage={pageLanguage}>Partners</Trans>
              </a>
            </Menu>
            <Menu>
              <a
                href={getUrl("blog")}
                target="_blank"
                rel="noopener noreferrer"
                className="text-base font-normal text-purple-100 hover:text-gray-darklight"
              >
                <Trans pageLanguage={pageLanguage}>Blog</Trans>
              </a>
            </Menu>
            <a
              href={getUrl("demo")}
              className="ml-8 whitespace-nowrap inline-flex items-center justify-center vc-btn-orange-v2"
            >
              <Trans pageLanguage={pageLanguage}>Schedule a Free Demo</Trans>
            </a>
            <LangSelect />
          </div>
        </div>
      </div>
      <div className={isOpen1 ? "" : "hidden"}>
        <div className="fixed z-50 top-0 inset-x-0 transition transform origin-top-right lg:hidden shadow-nav ">
          <div className="bg-white divide-y-2 divide-purple-50">
            <div className="pt-5 pb-6 px-4">
              <div className="flex items-center justify-between">
                <div>
                  <img
                    className="h-8 w-auto"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/vantagecms/uploads/2020/08/vantage-circle-logo-compressed.png"
                    alt="Vantage Circle"
                  />
                </div>
                <div className="-mr-2">
                  <button
                    type="button"
                    onClick={() => setIsOpen(!isOpen1)}
                    className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-purple-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500"
                  >
                    <span className="sr-only">Close menu</span>
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="p-2 mt-3 max-h-96 overflow-y-scroll">
                <nav className="grid gap-y-8 mobile-menu">
                  {/* Mobile Menu starts */}
                  <div className="content">
                    <div className="acc">
                      <div className="acc__card">
                        <div className="acc__title text-base haschildren">
                          <Trans pageLanguage={pageLanguage}>Solutions</Trans>
                        </div>
                        <div className="acc__panel">
                          <a
                            href={getUrl("vantage-rewards")}
                            className="-m-3 p-3 flex items-center rounded-md hover:bg-purple-50"
                          >
                            <img
                              src="https://res.cloudinary.com/vantagecircle/image/upload/w_30/vantagecms/uploads/2020/08/vantagerewards-icon.png"
                              alt="Vantage Rewards"
                              width="30"
                              height="30"
                            />
                            <span className="ml-3 text-base font-normal text-gray-900">
                              <Trans pageLanguage={pageLanguage}>
                                Vantage Rewards
                              </Trans>
                            </span>
                          </a>
                          <a
                            href={getUrl("vantage-perks")}
                            className="-m-3 p-3 flex items-center rounded-md hover:bg-purple-50"
                          >
                            <img
                              src="https://res.cloudinary.com/vantagecircle/image/upload/w_30/vantagecms/uploads/2020/08/vantageperks-icon.png"
                              alt="Vantage Perks"
                              width="30"
                              height="30"
                            />
                            <span className="ml-3 text-base font-normal text-gray-900">
                              <Trans pageLanguage={pageLanguage}>
                                Vantage Perks
                              </Trans>
                            </span>
                          </a>
                          <a
                            href="https://www.vantagefit.io/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="-m-3 p-3 flex items-center rounded-md hover:bg-purple-50"
                          >
                            <img
                              src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/06/vantagefit-logo.png"
                              alt="Vantage Fit"
                              width="30"
                              height="30"
                            />
                            <span className="ml-3 text-base font-normal text-gray-900">
                              <Trans pageLanguage={pageLanguage}>
                                Vantage Fit
                              </Trans>
                            </span>
                          </a>
                          <a
                            href={getUrl("vantage-pulse")}
                            className="-m-3 p-3 flex items-center rounded-md hover:bg-purple-50"
                          >
                            <img
                              src="https://res.cloudinary.com/vantagecircle/image/upload/w_30/vantagecms/uploads/2020/08/vantagepulse-icon.png"
                              alt="Vantage Pulse"
                              width="30"
                              height="30"
                            />
                            <span className="ml-3 text-base font-normal text-gray-900">
                              <Trans pageLanguage={pageLanguage}>
                                Vantage Pulse
                              </Trans>
                            </span>
                          </a>
                        </div>
                      </div>
                      <a
                        href={getUrl("pricing")}
                        className="-m-3 mb-2 p-3 flex items-center"
                      >
                        <span className="flex-1 text-base font-normal text-gray-900">
                          <Trans pageLanguage={pageLanguage}>Pricing</Trans>
                        </span>
                      </a>
                      <a
                        href={getUrl("coorporates")}
                        className="-m-3 mb-2 p-3 flex items-center"
                      >
                        <span className="flex-1 text-base font-normal text-gray-900">
                          <Trans pageLanguage={pageLanguage}>Clients</Trans>
                        </span>
                      </a>
                      <a
                        href={getUrl("partners")}
                        className="-m-3 mb-2 p-3 flex items-center"
                      >
                        <span className="flex-1 text-base font-normal text-gray-900">
                          <Trans pageLanguage={pageLanguage}>Partners</Trans>
                        </span>
                      </a>
                      {/* <a href="https://blog.vantagecircle.com/" className="-m-3 mb-2 p-3 flex items-center">
                                                <span className="flex-1 text-base font-normal text-gray-900">
                                                    <Trans>Blog</Trans>
                                                </span>
                                            </a> */}
                    </div>
                    <div className="pb-6">
                      <a href={getUrl("demo")} className="vc-colored-btn">
                        <Trans pageLanguage={pageLanguage}>
                          Schedule a Free Demo
                        </Trans>
                      </a>
                      <p className="text-base text-purple-500">
                        {/* <Trans pageLanguage={pageLanguage}>Existing customer</Trans>? */}
                        <a
                          href="https://app.vantagecircle.com/"
                          className="text-base text-purple-600"
                        >
                          &nbsp;
                          <Trans pageLanguage={pageLanguage}>Sign in</Trans>
                        </a>
                      </p>
                    </div>
                  </div>
                  {/* Mobile Menu ends */}
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderMultiLang
