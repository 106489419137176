import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import Header from "./header"
import NewHeader from "./Newheader"
import ReqDemoHeader from "./ReqDemoHeader"
import RewardsHeader from "./RewardsHeader"
import FreeTrialHeader from "./FreeTrialHeader"
import RegistrationHeader from "./RegistrationHeader"
import NewFooter from "./NewFooter"
import Footer from "./footer"
import "../styles/global.css"
import PaidNav from "./HeaderSelect/PaidNav"
import GartnerNav from "./HeaderSelect/GartnerNav"
import RnRnav from "./HeaderSelect/RnRnav"
import HearderCapt from "./HeaderSelect/HearderCapt"
import path from "path-browserify"
import { useLanguages } from "../hooks/useLanguages"
import ExitIntent from "./ExitIntent"
import ExitIntentDe from "./ExitIntentDe"
import ExitIntentEs from "./ExitIntentEs"
import ExitIntentFr from "./ExitIntentFr"
import OnlyProductsHeader from "./HeaderSelect/OnlyProductsHeader"
import MasterClassUSANav from "./MasterClassUSANav"
// import ExitIntentNl from "./ExitIntentNl"
// import ExitIntentPt from "./ExitIntentPt"
// import BacktoTop from "./BacktoTop"

const pagesWithNewFooter = require("../../static/constants").pagesWithNewFooter
const pageswithNOfooter = require("../../static/constants").pageswithNOfooter
const pageswithAIREfooter = require("../../static/constants")
  .pageswithAIREfooter

const excludedPathForExitPopup = [
  "/employee-rewards-and-recognition-platform-v1/",
  "/employee-rewards-and-recognition-program-v1/",
  "/employee-rewards-and-recognition-platform-v1/",
  "/employee-rewards-and-recognition-program-v1/",
  "/employee-rewards-and-recognition-program-v2/",
  "/employee-rewards-and-recognition-program-v3/",
  "/employee-rewards-and-recognition-platform-v2/",
  "/employee-rewards-and-recognition-platform-v3/",
  "/employee-recognition-program-v4/",
  "/request-demo/",
  "/schedule-demo/",
  "/thank-you-page-ads-program/",
  "/thank-you-page-ads-platform/",
  "/vantage-point-webcast-registration-in/",
  "/aire-framework-whitepaper-for-conference-attendees/",
  // "/products/rewards-recognition/",
  "/vantage-point-webcast-registration/",
  "/vantage-point-webcast-live/",
  "/corporate-diwali-offers/",
  "/privacy-policy-vc/",
  "/terms-and-conditions-vc/",
  "/fr/*",
  "/es/*",
  "/de/*",
  "/nl/*",
  "/pt/*",
  "/products/rewards-recognition/",
  "/products/employee-surveys/",
  "/products/employee-discount/",
  "/careers/",
  "/plans-and-pricing/",
  "/employeespeaks/",
  "/v1/",
  "/v2/",
  "/v3/",
  "/employee-engagement-survey-platform-v1/",
  "/masterclassUSA-HRCI/"
]

const Layout = ({ children, location, pageLanguage }) => {
  const [shadow, setshadow] = useState(false)
  const [height, setheight] = useState(0)
  const [width, setwidth] = useState(false)
  const [exitintent, setExitintent] = useState(false)
  let max = 100
  const [currLanguage] = useLanguages()

  //TO SHOW/HIDE EXIT INTENT AFTER 6 SECS
  //  const pageLanguage = pageContext.lang?.toLowerCase()
  const pathname = location?.pathname ? location.pathname : ""

  const renderExitIntent = () => {
    return (
      <div className={`${exitintent ? "block" : "hidden"}`}>
        {currLanguage === "en" && (
          <ExitIntent exitintent={exitintent} closepopup={closepopup} />
        )}
        {currLanguage === "fr" && (
          <ExitIntentFr exitintent={exitintent} closepopup={closepopup} />
        )}
        {currLanguage === "es" && (
          <ExitIntentEs exitintent={exitintent} closepopup={closepopup} />
        )}
        {currLanguage === "de" && (
          <ExitIntentDe exitintent={exitintent} closepopup={closepopup} />
        )}
        {/* {currLanguage === "nl" && (
          <ExitIntentNl exitintent={exitintent} closepopup={closepopup} />
        )}
        {currLanguage === "pt" && (
          <ExitIntentPt exitintent={exitintent} closepopup={closepopup} />
        )} */}
      </div>
    )
  }

  const closepopup = () => {
    setExitintent(false)
    localStorage.setItem("exitPopup", false)
  }

  // const handleMouseLeave = event => {
  //   if (checkIfCookieIsPresent()) {
  //     return
  //   }
  //   if (!checkIfCookieIsPresent()) {
  //     setExitintent(true)
  //     setCookieForExitIntent()
  //   }
  // }

  // const checkIfCookieIsPresent = () => {
  //   let availableCookies = document.cookie
  //   if (
  //     availableCookies.split(";").indexOf(" showExitIntent=true") !== -1 ||
  //     availableCookies.split(";").indexOf("showExitIntent=true") !== -1
  //   ) {
  //     return true
  //   } else return false
  // }

  // const setCookieForExitIntent = () => {
  //   let now = new Date()
  //   now.setTime(now.getTime() + 24 * 3600 * 1000)
  //   let cookieName = "showExitIntent"
  //   let cookieValue = "true"

  //   document.cookie = `${cookieName}=${cookieValue}; expires=${now.toUTCString()}; path=/`
  // }

  // useEffect(() => {
  //   let timeOut = 0
  //   if (excludedPathForExitPopup.includes(pathname)) return
  //   else {
  //     if (typeof window !== "undefined") {
  //       if (window.innerWidth > 768) {
  //         timeOut = setTimeout(() => {
  //           handleMouseLeave()
  //         }, 7000)
  //       }
  //     }
  //   }
  //   return () => {
  //     clearTimeout(timeOut)
  //   }
  // }, [])

  // END OF TO SHOW/HIDE EXIT INTENT AFTER 6 SECS

  const handleScroll = () => {
    setheight(window.scrollY)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    if (window.innerWidth < 770) {
      setwidth(true)
    } else {
      setwidth(false)
    }
  }, [])

  useEffect(() => {
    let per = (height / document.body.scrollHeight) * 100
    if (per > 11 && per < 83) {
      setshadow(true)
    } else {
      setshadow(false)
    }
  }, [height])

  const PaidNavProperties = {
    default: {
      logo: "2022/04/New-VC-Logo-invert.png",
      bgColor: "bg-indigo-100",
      textColor: "textpurple-100",
      shadow: "shadow-darkgray",
      buttonName: "Request a Demo",
      buttonLink: "#form",
    },
    branding: {
      logo: "2023/07/new-vc-logo.png",
      bgColor: "bg-white",
      textColor: "text-black",
      shadow: "shadow-darkgray",
      buttonName: "Schedule a Demo",
      buttonLink: "#form",
    },
  }

  const RnRNavProperties = {
    indigo: {
      logo: "2022/04/New-VC-Logo-invert.png",
      bgColor: "bg-indigo-100",
      textColor: "text-purple-100",
      shadow: "shadow-darkgray",
      logoSize: "",
      buttonName: "Request a Demo",
      buttonLink: "#form",
      tabClassName: "flex justify-center",
      buttonClassName: "flex justify-center xl:justify-end items-center",
      ids: {
        home: "#hero",
        features: "#features",
        caseStudies: "#casestudies",
        testimonials: "#feedbackCarousel",
      },

      navBarOptions: ["Home", "Features", "Case Studies", "Testimonials"],
    },
    white: {
      logo: "2023/07/new-vc-logo.png",
      bgColor: "bg-white",
      textColor: "text-black",
      shadow: "",
      logoSize: "scale-75",
      buttonName: "Request a Demo",
      buttonLink: "#form",
      tabClassName: "flex justify-center",
      buttonClassName: "flex justify-center xl:justify-end items-center",
      ids: {
        home: "#watchVideoClass",
        features: "#features",
        caseStudies: "#successStories",
        testimonials: "#integrations",
      },

      navBarOptions: ["Home", "Features", "Success Stories", "Integrations"],
    },
    whiteScheduleADemo: {
      logo: "2023/07/new-vc-logo.png",
      bgColor: "bg-white",
      textColor: "text-black",
      shadow: "",
      logoSize: "scale-75",
      buttonName: "Schedule a Demo",
      buttonLink: "#form",
      tabClassName: "flex justify-center",
      buttonClassName: "flex justify-center xl:justify-end items-center",
      ids: {
        home: "#watchVideoClass",
        features: "#features",
        caseStudies: "#successStories",
        testimonials: "#integrations",
      },

      navBarOptions: ["Home", "Features", "Success Stories", "Integrations"],
    },
    whiteDHTTNav: {
      logo: "2023/07/new-vc-logo.png",
      bgColor: "bg-white",
      textColor: "text-black",
      shadow: "",
      logoSize: "scale-75",
      buttonName: "Book now",
      buttonLink: "#form", 
      tabClassName: "flex justify-center",
      buttonClassName: "flex justify-center xl:justify-end items-center",
      ids: {
        home: "#Home",
        features: "#Events",
        caseStudies: "#Speaker",
      },
     
      navBarOptions: [
       "Home", "Events", "Speaker"
      ]
    },
    onlyProducts: {
      logo: "2023/07/new-vc-logo.png",
      bgColor: "bg-white",
      textColor: "text-black",
      shadow: "",
      logoSize: "scale-75",
      buttonName: "Talk to Us",
      buttonLink: "#form",
      tabClassName: "flex justify-end",
      buttonClassName: "flex justify-center items-center",
      ids: {
        home: "/products/rewards-recognition/",
        features: "/products/employee-surveys/",
        caseStudies: "/products/employee-discount/",
        testimonials: "https://www.vantagefit.io/",
      },

      navBarOptions: [
        "Vantage Rewards",
        "Vantage Pulse",
        "Vantage Perks",
        "Vantage Fit",
      ],
    },
    costSave: {
      logo: "2023/07/new-vc-logo.png",
      bgColor: "bg-white",
      textColor: "text-black",
      shadow: "",
      logoSize: "scale-75",
      buttonName: "Request Demo",
      buttonLink: "#form",
      tabClassName: "flex justify-end",
      buttonClassName: "flex justify-center items-center",
      ids: {
        home: "#home",
        features: "#caseStudy",
        caseStudies: "#products",
        testimonials: "#amazon",
      },

      navBarOptions: ["Home", "Case Study", "Products", "Amazon"],
    }
  }

  return (
    <>
      <div
        className={
          pathname ===
          (`/webinar-series-registration/` ||
            pathname === "/employee-recognition-v2/")
            ? "sticky top-0 w-full bg-white"
            : "hidden"
        }
      >
        {(pathname === `/webinar-series-registration/` ||
          pathname === `/employee-recognition-v2/`) && <NewHeader />}
      </div>
      <div
        className={
          pathname === `/request-demo/` || pathname === "/schedule-demo/"
            ? "sticky top-0 w-full bg-white"
            : "hidden"
        }
      >
        {(pathname === `/request-demo/` ||
          pathname === `/request-demo` ||
          pathname === "/schedule-demo/" ||
          pathname === "/schedule-demo") && <ReqDemoHeader />}
      </div>
      <div
        className={
          pathname === `/registration/` ||
          pathname === "/vantage-point-webcast-registration/" ||
          pathname === "/vantage-point-webcast-registration-in/"
            ? "sticky top-0 w-full bg-white"
            : "hidden"
        }
      >
        {(pathname === `/registration/` ||
          pathname === "/vantage-point-webcast-registration/" ||
          pathname === "/vantage-point-webcast-registration-in/") && (
          <RegistrationHeader />
        )}
      </div>
      <div
        className={
          pathname === `/start-free-trial/`
            ? "sticky top-0 w-full bg-white"
            : "hidden"
        }
      >
        {pathname === `/start-free-trial/` && <FreeTrialHeader />}
      </div>
      <div
        className={
          pathname === `/book-a-demo-capterra` ||
          pathname === `/book-a-demo-capterra/` ||
          pathname === `/book-a-demo-g2` ||
          pathname === `/book-a-demo-g2/` ||
          pathname === `/book-a-demo-sourceforge` ||
          pathname === `/book-a-demo-sourceforge/` ||
          pathname === `/book-a-demo-ssr` ||
          pathname === `/book-a-demo-ssr/`
            ? "sticky top-0 w-full bg-white"
            : "hidden"
        }
      >
        {(pathname === `/book-a-demo-capterra` ||
          pathname === `/book-a-demo-capterra/` ||
          pathname === `/book-a-demo-g2` ||
          pathname === `/book-a-demo-g2/` ||
          pathname === `/book-a-demo-sourceforge` ||
          pathname === `/book-a-demo-sourceforge/` ||
          pathname === `/book-a-demo-ssr` ||
          pathname === `/book-a-demo-ssr/`) && <HearderCapt />}
      </div>
      <div
        className={
          pathname === `/service-awards-budget-v1` ||
          pathname === `/service-awards-budget-v1/`
            ? "sticky top-0 w-full mb-4"
            : "hidden"
        }
      >
        {(pathname === `/service-awards-budget-v1` ||
          pathname === `/service-awards-budget-v1/`) && (
          <RnRnav navProperty={RnRNavProperties["costSave"]} />
        )}
      </div>

      <div className="sticky w-full">
        {(pathname === "/social-recognition-platform-v1/" ||
          pathname === "/paid-landing-page" ||
          pathname === "/paid-landing-page/" ||
          pathname === "/amazon-paid-page" ||
          pathname === "/amazon-paid-page/" ||
          pathname === "/employee-recognition-program-v2" ||
          pathname === "/employee-recognition-program-v2/" ||
          pathname === "/employee-recognition-program-v3" ||
          pathname === "/employee-recognition-program-v3/" ||
          pathname === "/employee-engagement-survey-platform-v1" ||
          pathname === "/employee-engagement-survey-platform-v1/") && (
          <PaidNav paidProperties={PaidNavProperties["default"]} />
        )}

        {(pathname === "/book-a-meeting-v1" ||
          pathname === "/book-a-meeting-v1/") && (
          <PaidNav paidProperties={PaidNavProperties["branding"]} />
        )}

        {(pathname === "/employee-rewards-recognition-program-v2/" ||
          pathname === "/employee-rewards-recognition-platform-v2/" ||
          pathname === "/employee-rewards-recognition-program-v3/" ||
          pathname === "/employee-rewards-recognition-platform-v3/" ||
          pathname === "/employee-rewards-recognition-platform-v1/" ||
          pathname === "/employee-rewards-recognition-program-v1/" ||
          pathname === "/v1" ||
          pathname === "/v1/" ||
          pathname === "/v2" ||
          pathname === "/v2/" ||
          pathname === "/v3" ||
          pathname === "/v3/" ||
          pathname === "/employee-recognition-program-v4/" ||
          pathname === "/employee-recognition-program-v4/") && (
          <RnRnav navProperty={RnRNavProperties["indigo"]} />
        )}

        {(pathname === "/RnR-GCC" ||
          pathname === "/RnR-GCC/" ||
          pathname === "/rewards-and-recognition-platform-v2" ||
          pathname === "/rewards-and-recognition-platform-v2/") && (
          <RnRnav navProperty={RnRNavProperties["white"]} />
        )}

        {
          (pathname === "/rewards-and-recognition-platform-v1" || pathname === "/rewards-and-recognition-platform-v1/" ) && 
          (
            <RnRnav navProperty={RnRNavProperties["whiteScheduleADemo"]} />
          )
        }
        {
          (pathname === "/dubai-hr-think-tank" || pathname === "/dubai-hr-think-tank/" ) && 
          (
            <RnRnav navProperty={RnRNavProperties["whiteDHTTNav"]} />
          )
        }
      </div>
      <div
        className={
          pathname === `/employee-recognition-v1/` ||
          pathname == `/employee-recognition-program-v1/` ||
          pathname === `/employee-recognition-v2/` ||
          pathname == `/employee-rewards-platform-v1/` ||
          pathname == `/employee-recognition-platform-v1/`
            ? width === false
              ? shadow
                ? "sticky top-0 w-full bg-white duration-300 shadow-xl"
                : "sticky top-0 w-full transition-{bg-indigo-500} duration-300 bg-indigo-100"
              : "sticky top-0 w-full transition-{bg-indigo-500} duration-300 bg-indigo-100"
            : "hidden"
        }
      >
        {(pathname === `/employee-recognition-v1/` ||
          pathname == `/employee-recognition-program-v1/` ||
          pathname == `/employee-rewards-platform-v1/` ||
          pathname === `/employee-recognition-v2/` ||
          pathname == `/employee-recognition-platform-v1/`) && (
          <RewardsHeader shadow={shadow} mobile={width} />
        )}
      </div>
      {/* <Header /> */}
      {!(
        pathname == `/webinar-series-registration/` ||
        pathname === "/vantage-point-webcast-live/" ||
        pathname == `/employee-recognition-v1/` ||
        pathname == `/employee-recognition-program-v1/` ||
        pathname === `/employee-recognition-v2/` ||
        pathname == `/employee-rewards-platform-v1/` ||
        pathname == `/employee-recognition-platform-v1/` ||
        pathname === `/request-demo/` ||
        pathname === `/schedule-demo/` ||
        pathname === `/start-free-trial/` ||
        pathname === "/registration/" ||
        pathname === "/vantage-point-webcast-registration/" ||
        pathname === "/vantage-point-webcast-registration-in/" ||
        pathname === "/employee-rewards-recognition-platform-v1/" ||
        pathname === "/employee-rewards-recognition-program-v1/" ||
        pathname === "/employee-rewards-recognition-program-v2/" ||
        pathname === "/employee-rewards-recognition-program-v3/" ||
        pathname === "/employee-rewards-recognition-platform-v3/" ||
        pathname === "/employee-rewards-recognition-platform-v2/" ||
        pathname === "/social-recognition-platform-v1/" ||
        pathname === "/rewards-and-recognition-buyers-guide-v1/" ||
        pathname === "/aire-framework-whitepaper-for-conference-attendees/" ||
        pathname === "/vantage-point-webcast-live-in/" ||
        pathname === "/paid-landing-page" ||
        pathname === "/paid-landing-page/" ||
        pathname === "/amazon-paid-page" ||
        pathname === "/amazon-paid-page/" ||
        pathname === "/privacy-policy-vc" ||
        pathname === "/privacy-policy-vc/" ||
        pathname === "/terms-and-conditions-vc" ||
        pathname === "/terms-and-conditions-vc/" ||
        pathname === "/employee-recognition-program-v2" ||
        pathname === "/employee-recognition-program-v2/" ||
        pathname === "/employee-recognition-program-v3" ||
        pathname === "/employee-recognition-program-v3/" ||
        pathname === "/employee-recognition-program-v4" ||
        pathname === "/employee-recognition-program-v4/" ||
        pathname === "/v1" ||
        pathname === "/v1/" ||
        pathname === "/v2" ||
        pathname === "/v2/" ||
        pathname === "/v2" ||
        pathname === "/v2/" ||
        pathname === "/v3" ||
        pathname === "/v3/" ||
        pathname === "/book-a-demo-capterra" ||
        pathname === "/book-a-demo-capterra/" ||
        pathname === `/book-a-demo-g2` ||
        pathname === `/book-a-demo-g2/` ||
        pathname === `/book-a-demo-sourceforge` ||
        pathname === `/book-a-demo-sourceforge/` ||
        pathname === `/book-a-demo-ssr` ||
        pathname === `/book-a-demo-ssr/` ||
        pathname === `/request-a-demo` ||
        pathname === `/request-a-demo/` ||
        pathname === `/employee-engagement-survey-platform-v1` ||
        pathname === `/employee-engagement-survey-platform-v1/` ||
        pathname === "/events/shrmtech24/hyderabad" ||
        pathname === "/events/shrmtech24/hyderabad/" ||
        pathname === "/events/shrmgcc" ||
        pathname === "/events/shrmgcc/" ||
        pathname === "/RnR-GCC" ||
        pathname == "/RnR-GCC/" ||
        pathname == "/services/vantage-swags-corporate-gifting" ||
        pathname == "/services/vantage-swags-corporate-gifting/" ||
        pathname === "/book-a-meeting-v1" ||
        pathname === "/book-a-meeting-v1/" ||
        pathname === "/rewards-and-recognition-platform-v2" ||
        pathname === "/rewards-and-recognition-platform-v2/" ||
        pathname === "/rewards-and-recognition-platform-v1" ||
        pathname === "/rewards-and-recognition-platform-v1/" ||
        pathname === "/dubai-hr-think-tank" ||
        pathname === "/dubai-hr-think-tank/" ||
        pathname === "/service-awards-budget-v1" ||
        pathname === "/service-awards-budget-v1/" ||
        pathname === "/masterclassUSA-HRCI" ||
        pathname === "/masterclassUSA-HRCI/" ||
        pathname ===  "/corporate-diwali-offers/" ||
        pathname === "/events/shrmIAC" ||
        pathname === "/events/shrmIAC/" ||
        pathname === "/events/hrse" ||
        pathname === "/events/hrse/" ||
        pathname === "/advertise-with-us-v1/" ||
        pathname === "/advertise-with-us-v1"
      ) && <Header pageLanguage={pageLanguage} />}

      <div className="sticky top-0 w-full">
        {(pathname ===`/masterclassUSA-HRCI` || 
          pathname ===`/masterclassUSA-HRCI/`
        ) && (
          <MasterClassUSANav navProperty={RnRNavProperties["onlyProducts"]} />
        )}
      </div>

      <div className="sticky top-0 w-full">
        {pathname ===`/aire-framework-whitepaper-for-conference-attendees/` && (
          <GartnerNav />
        )}
      </div>

      <div className="sticky top-0 w-full">
        {(pathname === "/services/vantage-swags-corporate-gifting/" ||
          pathname === "/corporate-diwali-offers/") && (
          <RnRnav navProperty={RnRNavProperties["onlyProducts"]} />
        )}
      </div>
      <main className="main-wrapper">{children}</main>
      {/* <BacktoTop /> */}

      {pageswithNOfooter.includes(pathname)
        ? null
        : !pageswithNOfooter.includes(pathname) &&
          (pagesWithNewFooter.includes(pathname) ? (
            <NewFooter />
          ) : (
            <Footer pageLanguage={pageLanguage} />
          ))}

      {renderExitIntent()}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: "",
}
export default Layout
